import { DatePipe } from '@angular/common';
import { inject, Injectable } from '@angular/core';
//import * as FileSaver from 'file-saver';
// import { saveAs } from 'file-saver-es';
import * as XLSX from 'xlsx';

const EXCEL_EXTENSION = 'xlsx';

@Injectable({
    providedIn: 'root',
})
export class ExportService {
    datePipe = inject(DatePipe);

    constructor() { }

    public exportExcel(json: any[], excelFileName: string): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const workbook: XLSX.WorkBook = {
            Sheets: { data: worksheet },
            SheetNames: ['data'],
        };

        this.fixWidth(worksheet);

        const date = this.datePipe.transform(new Date().getTime(), 'yyyy-MM-dd-h:mm:ss a');
        const fileName = excelFileName + '_Export_' + date + '.' + EXCEL_EXTENSION;
        XLSX.writeFile(workbook, fileName, {
            bookType: EXCEL_EXTENSION,
            type: 'buffer',
        });
    }

    private fixWidth(worksheet: XLSX.WorkSheet) {
        const data = XLSX.utils.sheet_to_json<any>(worksheet);
        const colLengths = Object.keys(data[0]).map((k) => k.toString().length);
        for (const d of data) {
            Object.values(d).forEach((element: any, index) => {
                console.log(element);
                const length = element === null ? 0 : element.toString().length;
                if (colLengths[index] < length) {
                    colLengths[index] = length;
                }
            })
        }
        worksheet["!cols"] = colLengths.map((l) => {
            return {
                wch: l,
            }
        })
    }
}
