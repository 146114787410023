<div class="wrapper">
    <app-enterprise-navigation></app-enterprise-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>

        <div>
            <section class="add-section width-50">
                <div>
                    <div class="row">
                        <div class="col-md-6 section-heading">Cashbacks Redeemed Breakdown</div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <small>AVAILABLE:</small>
                        </div>
                        <div class="col-md-6">
                            <small>TOTAL CASHBACKS REDEEMED TODAY:</small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <span>{{ balance || '0.00' | number: amountFormat }}</span>
                        </div>
                        <div class="col-md-6">
                            <span>{{
                                cashbacksRedeemedToday || '0.00' | number: amountFormat
                            }}</span>
                        </div>
                    </div>
                </div>
            </section>
            <section class="add-section">
                <form [formGroup]="searchForm">
                    <!-- <div class="row">
                        <div class="col-md-12 section-heading">Search</div>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            <div class="input-icons">
                                <i class="fa fa-search icon" aria-hidden="true"></i>
                                <input
                                    name="text"
                                    class="form-input"
                                    type="text"
                                    placeholder="Enter Product, Wholesaler, or Supplier Name"
                                    formControlName="text" />
                                @if (searchForm.get('text').value.length > 0) {
                                    <a (click)="clearText()">
                                        <img src="assets/images/close-x-purple.png" />
                                    </a>
                                }
                            </div>
                        </div>
                        <div class="col-md-7">
                            <button class="search-button" (click)="submit()">Search</button>
                            <a class="advanced-search" (click)="toggleAdvancedSearch()">
                                <img src="assets/images/dropdown.png" />
                                <u>ADVANCED SEARCH</u>
                            </a>
                        </div>
                    </div> -->
                    <!-- @if (showAdvancedSearch) { -->
                    <hr />
                    <div class="search-form">
                        <div class="row">
                            <div class="col-md-4">
                                <label for="fromDateId" class="form-label date-label">
                                    START DATE
                                </label>
                                <div class="datepicker">
                                    <input
                                        id="fromDateId"
                                        matInput
                                        class="datepicker-input"
                                        [min]="minDate"
                                        [max]="maxDate"
                                        [matDatepicker]="picker"
                                        name="fromDate"
                                        formControlName="fromDate"
                                        readonly />
                                    <mat-datepicker-toggle
                                        matSuffix
                                        [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label for="toDateId" class="form-label date-label">
                                    END DATE
                                </label>
                                <div class="datepicker">
                                    <input
                                        id="toDateId"
                                        matInput
                                        class="datepicker-input"
                                        [min]="searchForm.get('fromDate').value"
                                        [max]="maxDate"
                                        [matDatepicker]="picker1"
                                        name="toDate"
                                        formControlName="toDate"
                                        readonly />
                                    <mat-datepicker-toggle
                                        matSuffix
                                        [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label class="form-label"></label>
                                <div>
                                    <button class="search-button" (click)="submit()">Search</button>
                                    <a
                                        class="p-3 text-danger line-height-3"
                                        (click)="defaultDates()">
                                        <u>Default Dates</u>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="searched-items">
                        <div *ngFor="let key of searchItems | keyvalue">
                            <div *ngIf="key.value?.length > 0">
                                <div *ngFor="let item of key.value" class="search-item-value">
                                    {{ item | humanize }}
                                    <!-- <a (click)="removeSearchItem(key.key, item)">
                                            <img src="assets/images/close-x-white.png" />
                                        </a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- } -->
                </form>
            </section>
        </div>

        @if (showSearchResultRow) {
            <section>
                <div class="container-fluid tab-pane">
                    <br />
                    <div class="row">
                        <div class="col-md-10 section-heading">
                            Search Results: {{ cashbacksRedeemed.length }} of
                            {{ pagination.total }}
                        </div>
                        <div class="col-md-2">
                            <button
                                class="custombtn custombtn-align-right"
                                (click)="getDownloadData()">
                                <i class="fa fa-arrow-up" aria-hidden="true"></i>Export Data
                            </button>
                            <a
                                appExportExcel
                                hidden
                                [data]="excelDataList"
                                fileName="CashbacksRedeemed"
                                id="{{ downloadLinkId }}"
                                class="download-transaction">
                                <u>DOWNLOAD REPORTS</u>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        }

        <hr />

        @if (isLoading) {
            <div class="progress-container">
                <div class="progress-container-text">
                    <div>{{ loaderText }}</div>
                </div>
                <div>
                    <div class="custom-progress-bar">
                        <span [style.width.%]="progressWidth"></span>
                    </div>
                </div>
            </div>
        }

        <div class="container-fluid tab-pane custom-table custom-last-row">
            <br />
            <table>
                <thead>
                    <tr class="form-table-row">
                        <th>
                            <app-filter-arrow
                                filter="customer"
                                title="CUSTOMER"
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>
                        <th>
                            <app-filter-arrow
                                filter="orderNumber"
                                title="ORDER NR"
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>
                        <th>
                            <app-filter-arrow
                                filter="saleInclusive"
                                title="SALE INCL"
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>
                        <th>
                            <app-filter-arrow
                                filter="cashierName"
                                title="CASHIER NAME"
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>
                        <th>
                            <app-filter-arrow
                                filter="cashierNo"
                                title="CASHIER NO."
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>
                        <th>
                            <app-filter-arrow
                                filter="date"
                                title="DATE"
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>
                        <th>
                            <app-filter-arrow
                                filter="cashbackRedeemed"
                                title="CASHBACK REDEEMED"
                                (filterValue)="receiveFilter($event)">
                            </app-filter-arrow>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container>
                        @for (cr of cashbacksRedeemed; track cr?.shopifyOrder?.orderId) {
                            <tr class="form-table-row">
                                <td>
                                    <strong>{{ cr.customer }}</strong>
                                </td>
                                <td>{{ cr?.shopifyOrder?.orderNumber }}</td>
                                <td>{{ cr?.shopifyOrder?.totalAmount }}</td>
                                <td>{{ cr?.enStaffName }}</td>
                                <td>{{ cr?.enStaffNumber }}</td>
                                <td>{{ cr.createdAt | date: 'd/M/yyyy - h:mm a' }}</td>
                                <td>
                                    {{
                                        cr.cashbackAmountRedeemed
                                            | currency: 'ZAR' : 'symbol-narrow'
                                    }}
                                </td>
                            </tr>
                        } @empty {
                            <tr>
                                <div class="data-empty">
                                    <div class="col-md-12">No data to display</div>
                                </div>
                            </tr>
                        }
                    </ng-container>
                </tbody>
                <tfoot *ngIf="pagination.showPagination()">
                    <tr>
                        <td>
                            Rows per page:
                            <span>
                                <b>{{ pagination.length }}</b>
                            </span>
                            <app-page-length-dropdown (emitLength)="changeLength($event)">
                            </app-page-length-dropdown>
                        </td>
                        <td>
                            <app-page-navigation
                                pageNumber="{{ pagination.getPageNo() }}"
                                totalPages="{{ pagination.getTotalPages() }}"
                                (changePage)="changePage($event)">
                            </app-page-navigation>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>

        <div class="control-sidebar-bg"></div>
    </div>
</div>
